import axios from 'axios';
import { handleCsrfToken, toCamelCase } from '@/src/util/service-mixins.js';

export default class FeaturesService {
  constructor() {
    Object.assign(this, handleCsrfToken);
    this.assignCsrfToken();
  }

  async getFeatureFlippers() {
    const requestUrl = '/api/feature_flippers';

    try {
      const response = await axios.get(requestUrl);

      return toCamelCase(response.data);
    } catch (error) {
      return {};
    }
  }

  async getPaywallLaunchDates() {
    const requestUrl = '/api/subscriptions/launch_dates';
    try {
      const response = await axios.get(requestUrl);
      return toCamelCase(response.data);
    } catch (error) {
      return {};
    }
  }

  async getAnnouncementData() {
    const requestUrl = '/api/feature_params/announcement_banner_data';
    try {
      const response = await axios.get(requestUrl);
      return toCamelCase(response.data);
    } catch (error) {
      console.error('Error fetching announcement data:', error);
      return {};
    }
  }
}
