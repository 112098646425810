import FeaturesService from '@/src/common/services/features-service.js';
import { toCamelCase } from '@/src/util/service-mixins.js';

const featuresService = new FeaturesService();

const AppConfigStore = {
  state: {
    appConfig: {},
    featureFlippers: {},
    announcementData: {},
    dismissedAnnouncements: {},
    launchDates: {},
  },

  mutations: {
    INITIALIZE_APP_CONFIG(state, appConfig) {
      state.appConfig = toCamelCase(appConfig);
    },

    INITIALIZE_FEATURE_FLIPPERS(state, featureFlippers) {
      state.featureFlippers = featureFlippers;
    },

    INITIALIZE_ANNOUNCEMENT_DATA(state, announcementData) {
      state.announcementData = announcementData;
    },

    LOAD_DISMISSED_ANNOUNCEMENTS(state) {
      try {
        state.dismissedAnnouncements = JSON.parse(localStorage.getItem('dismissedBanners')) || {};
      } catch (error) {
        console.error('Failed to parse localStorage:', error);
        state.dismissedAnnouncements = {};
      }
    },

    DISMISS_ANNOUNCEMENT(state) {
      const key = AppConfigStore.getters.announcementKey(state);
      if (!key) return;

      state.dismissedAnnouncements[key] = true;

      try {
        localStorage.setItem('dismissedBanners', JSON.stringify(state.dismissedAnnouncements));
      } catch (error) {
        console.error('Failed to save dismissed announcements:', error);
      }
    },

    INITIALIZE_PAYWALL_LAUNCH_DATES(state, launchDates) {
      state.launchDates = launchDates;
    },
  },

  actions: {
    initializeAppConfig(context, appConfig) {
      context.commit('INITIALIZE_APP_CONFIG', appConfig);
    },

    async initializeFeatureFlippers(context) {
      const featureFlippers = await featuresService.getFeatureFlippers();
      context.commit('INITIALIZE_FEATURE_FLIPPERS', featureFlippers);
    },

    async initializeAnnouncementData({ commit }) {
      try {
        const announcementData = await featuresService.getAnnouncementData();
        commit('INITIALIZE_ANNOUNCEMENT_DATA', {
          text: announcementData.announcementBannerText?.trim() || '',
          link: announcementData.announcementBannerLink?.trim() || '',
          linkText: announcementData.announcementBannerLinkText?.trim() || 'Learn More',
        });
      } catch (error) {
        console.error('Failed to fetch announcement:', error);
      }
    },

    loadDismissedAnnouncements({ commit }) {
      commit('LOAD_DISMISSED_ANNOUNCEMENTS');
    },

    dismissAnnouncement({ commit }) {
      commit('DISMISS_ANNOUNCEMENT');
    },

    async initializePaywallLaunchDates(context) {
      const launchDates = await featuresService.getPaywallLaunchDates();
      context.commit('INITIALIZE_PAYWALL_LAUNCH_DATES', launchDates);
    },
  },

  getters: {
    currentUser() {
      // This user only has attributes defined in app/views/layouts/common/_current_user.html.erb
      return toCamelCase(window.CURRENT_USER);
    },

    isCurrentUserAdmin(_state, getters) {
      return getters.currentUser.role === 'admin';
    },

    isCurrentUserManager(_state, getters) {
      return getters.currentUser.role === 'manager';
    },

    isCurrentUserTech(_state, getters) {
      return getters.currentUser.role === 'tech';
    },

    isCurrentUserOrgRestricted(_state, getters) {
      return getters.currentUser.isOrgRestricted;
    },

    announcementData(state) {
      return state.announcementData;
    },

    announcementKey(state) {
      if (!state.announcementData?.text) return '';

      const { text, link, linkText } = state.announcementData;
      return `${text.toLowerCase().trim()}||${link.toLowerCase().trim()}||${linkText
        .toLowerCase()
        .trim()}`;
    },

    isAnnouncementVisible(state, getters) {
      const announcementKey = getters.announcementKey;
      return Boolean(announcementKey && !state.dismissedAnnouncements[announcementKey]);
    },
  },
};

export default Object.freeze(AppConfigStore);
